.component {
  position: relative;
  z-index: 0;
  display: none;
  flex-direction: column;
  gap: var(--size-16);

  @media (--viewport-md) {
    position: sticky;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      "tile-1 tile-2"
      "tile-3 tile-4";
  }

  & > :nth-child(1) {
    @media (--viewport-md) {
      z-index: 1;
      width: 100%;
      grid-area: tile-1;
      align-self: flex-end;
    }
  }

  & > :nth-child(2) {
    @media (--viewport-md) {
      width: 100%;
      grid-area: tile-2;
      align-self: flex-end;
    }
  }

  & > :nth-child(3) {
    @media (--viewport-md) {
      z-index: 2;
      width: 70%;
      grid-area: tile-3;
      align-self: flex-start;
      justify-self: flex-end;
    }
  }

  & > :nth-child(4) {
    @media (--viewport-md) {
      width: 100%;
      grid-area: tile-4;
      align-self: flex-start;
    }
  }
}

.componentRewardCalculatorTile,
.gridItem {
  pointer-events: auto;
  transition: transform var(--duration-450) var(--ease-in-out);

  &:nth-child(4) {
    transform: scale(1);
  }

  & > .calculatorLayout {
    width: 100%;
  }

  &.compact {
    pointer-events: none;

    &:nth-child(1) {
      transform: translateX(50%) translateY(40%);
    }

    &:nth-child(2) {
      transform: translateX(50%);
    }

    &:nth-child(3) {
      transform: translateX(10%);
    }

    &:nth-child(4) {
      transform: translateX(40%) translateY(30%) scale(0.8);
    }
  }
}
